import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import { Content } from './Content'
import { Button } from './components/Button'

export function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary
        fallback={
          <div className="flex min-h-screen flex-col items-center justify-center">
            <div className="py-4">Une erreur est survenue</div>
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              Rafraîchir
            </Button>
          </div>
        }
      >
        <Content />
        {import.meta.env.MODE !== 'production' && (
          <div className="pointer-events-none fixed bottom-2 right-2 text-lime-300">
            <b>STAGING</b>
          </div>
        )}
      </ErrorBoundary>
    </React.StrictMode>
  )
}
