import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { formatTime } from '../utils/time'

type Props = { start: DateTime; end: DateTime; count: number }

export const TimeMarks = ({ start, end, count }: Props) => {
  const timeMarks = useMemo(() => {
    const timeMarks = []
    const duration = (end.valueOf() - start.valueOf()) / count
    for (let i = 0; i <= count; i++) {
      timeMarks.push(start.plus({ milliseconds: duration * i }))
    }

    return timeMarks
  }, [start, end, count])

  return (
    <div className="flex flex-row justify-between">
      {timeMarks.map((time) => (
        <span key={time.valueOf()}>{formatTime(time)}</span>
      ))}
    </div>
  )
}
