import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import React, { useMemo } from 'react'
import { AwsCredentials, s3BucketName, s3BucketRegion } from 'shared/types/aws'

type Props = AwsCredentials & {
  sessionToken: string
} & {
  children: (s3Client: S3Client) => React.ReactNode
}

export const S3Wrapper = ({
  children,
  accessKeyId,
  secretAccessKey,
  sessionToken,
}: Props) => {
  const s3Client = useMemo(() => {
    return new S3Client({
      credentials: { accessKeyId, secretAccessKey, sessionToken },
      region: s3BucketRegion,
    })
  }, [accessKeyId, secretAccessKey, sessionToken])

  return <>{children(s3Client)}</>
}

export async function getS3Url(key: string, client: S3Client) {
  const command = new GetObjectCommand({ Bucket: s3BucketName, Key: key })
  const url = await getSignedUrl(client, command, { expiresIn: 3600 })
  return url
}
