import { S3Client } from '@aws-sdk/client-s3'
import { Alert } from 'common/types'
import { DateTime, Settings } from 'luxon'
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { TimeMarks } from 'shared/components/TimeMarks'
import { useInterval } from 'shared/hooks/useInterval'
import { roomDisplayName } from 'shared/utils/room'
import { formatMsTime, ms, ms2sec, sec2ms } from 'shared/utils/time'
import { onError } from 'shared/utils/web/error'
import { S3Sound, fetchData, formatPrefix } from 'shared/utils/web/fetchData'
import { S3Wrapper, getS3Url } from './S3Wrapper'

const BUCKET_DURATION = ms(1, 'minute')
const SOUND_DURATION = ms(9.6, 'seconds')

type Props = {
  alert: Alert
  s3Client: S3Client
}

export type SoundData = S3Sound

function verticalBlockStyle(
  left: number,
  right: number,
  start: number,
  range: number,
) {
  return {
    left: `${((left - start) / range) * 100}%`,
    width: `${((right - left) / range) * 100}%`,
  }
}

type Prefix = string
// undefined = prefix loading is not yet started
// null = prefix loading is in progress
type PrefixDataMap = Record<Prefix, SoundData[] | undefined | null>

const TimeLine_ = ({ alert, s3Client }: Props) => {
  const { serial, room, time, type, start, end, timeZone } = alert

  // Make sure all DateTime further created are in the correct zone
  Settings.defaultZone = timeZone

  const startDate = DateTime.fromMillis(start)
  const endDate = DateTime.fromMillis(end)

  const bucketPrefixes: string[] = useMemo(() => {
    let date = DateTime.fromMillis(start).startOf('minute')
    const prefixes = []
    while (date.valueOf() <= end) {
      const prefix = date.toISO().slice(0, 16) // round to minute
      prefixes.push(prefix)
      date = date.plus({ milliseconds: BUCKET_DURATION })
    }
    return prefixes
  }, [start, end])

  const [prefixDataMap, setPrefixDataMap] = useState<PrefixDataMap>(() =>
    bucketPrefixes.reduce(
      (acc, prefix) => ({ ...acc, [prefix]: undefined }),
      {},
    ),
  )

  // Load all files from s3 for this prefix
  const loadBucket = useCallback(
    async (prefix: string) => {
      try {
        const formattedPrefix = formatPrefix(serial, prefix)
        const data = await fetchData(s3Client, formattedPrefix)

        setPrefixDataMap((prevPrefixDataMap) => ({
          ...prevPrefixDataMap,
          [prefix]: data,
        }))
      } catch {
        onError
      }
    },
    [s3Client, serial],
  )

  const trackRef = useRef<HTMLDivElement>(null)

  const [isPlaying, setIsPlaying] = useState(false)
  const [percent, setPercent] = useState(0)

  const [currentSoundKey, setCurrentSoundKey] = useState<string>()
  const soundURLs = useRef<Record<string, string>>({})
  const audioRefs = useRef<Record<string, React.RefObject<HTMLAudioElement>>>(
    {},
  )
  const [isLive, setIsLive] = useState(false)

  // Flatten all prefixes into a single array of sounds
  const data = useMemo(
    () =>
      bucketPrefixes.reduce<SoundData[]>((acc, prefix) => {
        acc.push(...(prefixDataMap[prefix] ?? []))
        return acc
      }, []),
    [bucketPrefixes, prefixDataMap],
  )

  // Compute or update soundURLs
  useEffect(() => {
    data.forEach(async ({ soundKey }) => {
      if (soundURLs.current[soundKey] === undefined) {
        soundURLs.current[soundKey] = await getS3Url(soundKey, s3Client)
      }
    })
  }, [s3Client, data])

  // Compute or update audio refs
  useEffect(() => {
    data.forEach(({ soundKey }) => {
      if (audioRefs.current[soundKey] === undefined)
        audioRefs.current[soundKey] = React.createRef<HTMLAudioElement>()
    })
  }, [data])

  // Set sound index before instant when all prefix buckets are loaded
  useEffect(() => {
    // Make sure this is done only once
    if (currentSoundKey !== undefined) return
    if (data.length === 0) return

    if (
      Object.values(prefixDataMap).every(
        (soundData) => soundData !== null && soundData !== undefined,
      )
    ) {
      // Done loading all bucket prefixes
      let i = data.length - 1
      while (i > 0 && data[i].endTimestamp > time) i--
      setCurrentSoundKey(data[i].soundKey)
    }
  }, [prefixDataMap, data, currentSoundKey, time])

  const currentAudioElement =
    currentSoundKey && audioRefs.current
      ? audioRefs.current[currentSoundKey]?.current ?? null
      : null

  const setTimeStamp = useCallback(
    (timestamp: number) => {
      let index
      let localTimestamp = 0
      for (index = 0; index < data.length; index++) {
        const { endTimestamp } = data[index]
        const startTimestamp = endTimestamp - SOUND_DURATION
        if (timestamp <= endTimestamp && timestamp >= startTimestamp) {
          localTimestamp = ms2sec(timestamp - startTimestamp)
          break
        }
        if (startTimestamp > timestamp) {
          break
        }
      }

      if (index < data.length) {
        const newSoundKey = data[index].soundKey
        if (newSoundKey !== currentSoundKey) {
          currentAudioElement?.pause()
          setCurrentSoundKey(newSoundKey)
        }

        const newAudioElement = audioRefs.current[newSoundKey].current
        if (newAudioElement === null) return

        newAudioElement.currentTime = localTimestamp
        if (isPlaying) newAudioElement.play()
      }
    },
    [data, currentSoundKey, currentAudioElement, audioRefs, isPlaying],
  )

  // Helper function for prev/next
  const startPlayAtIndex = useCallback(
    (index: number) => {
      currentAudioElement?.pause()
      const soundKey = data[index].soundKey
      setCurrentSoundKey(soundKey)
      const audioElement = audioRefs.current[soundKey].current
      if (audioElement === null) return
      audioElement.currentTime = 0
      if (isPlaying) audioElement.play()
    },
    [currentAudioElement, data, isPlaying],
  )

  const getCurrentSoundIndex = useCallback(
    () => data.findIndex((soundData) => soundData.soundKey === currentSoundKey),
    [data, currentSoundKey],
  )

  const previousSound = useCallback(() => {
    if (currentAudioElement === null) return
    const currentSoundIndex = getCurrentSoundIndex()

    if (sec2ms(currentAudioElement.currentTime) < 0.1 * SOUND_DURATION) {
      if (currentSoundIndex > 0) {
        startPlayAtIndex(currentSoundIndex - 1)
      }
    } else {
      currentAudioElement.currentTime = 0
    }
  }, [getCurrentSoundIndex, startPlayAtIndex, currentAudioElement])

  const nextSound = useCallback(() => {
    const currentSoundIndex = getCurrentSoundIndex()

    if (currentSoundIndex < data.length - 1) {
      startPlayAtIndex(currentSoundIndex + 1)
    }
  }, [data, getCurrentSoundIndex, startPlayAtIndex])

  // Handle sound player events, play next at end
  useEffect(() => {
    if (currentAudioElement === null) return

    const handleError = (error: ErrorEvent) =>
      console.error('Audio error', error)

    currentAudioElement.addEventListener('ended', nextSound)
    currentAudioElement.addEventListener('error', handleError)

    return () => {
      currentAudioElement.removeEventListener('ended', nextSound)
      currentAudioElement.removeEventListener('error', handleError)
    }
  }, [currentAudioElement, nextSound])

  // Update percent while playing
  function updatePercent() {
    if (!currentAudioElement) {
      // Hacky, force a repaint to update currentAudioElement
      setPercent(0.001 * Math.random())
      return
    }

    const currentSoundIndex = getCurrentSoundIndex()

    const soundStartTimeStamp =
      data[currentSoundIndex].endTimestamp - SOUND_DURATION
    const localCurrentTimestamp = sec2ms(currentAudioElement.currentTime)
    const globalCurrentTimestamp = soundStartTimeStamp + localCurrentTimestamp

    // Clamp at start and end
    if (soundStartTimeStamp > end) currentAudioElement.pause()

    if (globalCurrentTimestamp < start)
      currentAudioElement.currentTime = ms2sec(start - soundStartTimeStamp)

    setIsPlaying(!currentAudioElement.paused)

    setPercent(((globalCurrentTimestamp - start) / (end - start)) * 100)
  }

  useInterval(updatePercent, 250)

  const togglePlayPause = useCallback(() => {
    if (currentAudioElement === null) return

    if (currentAudioElement.paused) currentAudioElement.play()
    else currentAudioElement.pause()
  }, [currentAudioElement])

  const handleTrackClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (trackRef.current === null) return
      const trackRect = trackRef.current.getBoundingClientRect()
      const percent = (event.clientX - trackRect.left) / trackRect.width
      setTimeStamp(start + (end - start) * percent)
    },
    [start, end, setTimeStamp],
  )

  // Keypress handler
  useEffect(() => {
    if (currentAudioElement === null) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.preventDefault()
        togglePlayPause()
      } else if (e.key === 'ArrowLeft') {
        previousSound()
      } else if (e.key === 'ArrowRight') nextSound()
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [currentAudioElement, togglePlayPause, previousSound, nextSound])

  // Trigger a loadBucket when new buckets are added
  useEffect(() => {
    for (const prefix of bucketPrefixes) {
      if (prefixDataMap[prefix] === undefined) {
        setPrefixDataMap((prevPrefixDataMap) => ({
          ...prevPrefixDataMap,
          [prefix]: null,
        }))
        loadBucket(prefix)
      }
    }
  }, [bucketPrefixes, prefixDataMap, loadBucket])

  // Trigger a bucket prefix refresh periodically in live mode
  const refreshCurrentBucket = useCallback(() => {
    const currentPrefix = DateTime.now().toISO().slice(0, 16)
    const bucketPrefixIndex = bucketPrefixes.indexOf(currentPrefix)
    // Check previous bucket in case new sounds were added
    if (bucketPrefixIndex > 0) {
      loadBucket(bucketPrefixes[bucketPrefixIndex - 1])
    }
    if (bucketPrefixIndex >= 0) {
      loadBucket(currentPrefix)
      setTimeout(refreshCurrentBucket, SOUND_DURATION)
    }
  }, [bucketPrefixes, loadBucket])

  // Set live mode if current timestamp is in interval
  const evaluateIsLive = useCallback(() => {
    const now = DateTime.now().valueOf()
    const live = now >= start && now < end
    setIsLive(live)
    // Update live state in 30 seconds
    if (live) setTimeout(evaluateIsLive, ms(30, 'seconds'))
    // Start refresh when state changes from false to true
    if (!isLive && live) refreshCurrentBucket()
  }, [start, end, isLive, refreshCurrentBucket])

  // Trigger live mode if applicaple
  useEffect(evaluateIsLive, [evaluateIsLive])

  if (isNaN(start) || isNaN(end))
    return (
      <div className="flex h-36 flex-col items-center justify-center bg-pink-600">
        Données invalides
      </div>
    )

  return (
    <div className="flex flex-col gap-10 px-4 py-2">
      <div className="flex flex-col justify-start gap-2">
        <div className="text-lg font-bold">{roomDisplayName(room)}</div>
        <div className="text-md">
          {type} détecté à {formatMsTime(time)}
        </div>
      </div>

      <div className="flex flex-1 flex-col gap-2">
        <div
          className="relative h-12 bg-black bg-opacity-20"
          ref={trackRef}
          onClick={handleTrackClick}
        >
          {Object.entries(prefixDataMap).map(([prefix, data]) => {
            if (!data) {
              const left = DateTime.fromISO(prefix).valueOf()
              const right = Math.min(left + BUCKET_DURATION, end)
              return (
                <div
                  key={prefix}
                  className="absolute inset-y-0 bg-slate-400 bg-opacity-30"
                  style={verticalBlockStyle(left, right, start, end - start)}
                />
              )
            } else {
              return data.map((soundData) => {
                const ts = soundData.endTimestamp
                const left = Math.max(ts - SOUND_DURATION, start)
                const right = Math.min(ts, end)
                if (right < left) return null
                return (
                  <div
                    className={'absolute inset-y-0 bg-blue-500'}
                    style={verticalBlockStyle(left, right, start, end - start)}
                    key={soundData.soundKey}
                  ></div>
                )
              })
            }
          })}
          <div
            className="absolute -bottom-1 -top-1 w-0.5 bg-white"
            style={{ left: `${percent}%` }}
          />
          <div
            className="absolute top-0 -ml-2.5 -mt-3 flex h-5 w-5 flex-row items-center justify-center rounded-full bg-pink-500"
            style={{
              left: `${
                ((time - SOUND_DURATION / 2 - start) / (end - start)) * 100
              }%`,
            }}
          >
            <div className="font-bold text-white">!</div>
          </div>
          <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center">
            {data.length === 0 && (
              <div className="rounded-md bg-slate-500 bg-opacity-50 px-4 py-1">
                Aucun son sur cette période
              </div>
            )}
          </div>
        </div>
        <TimeMarks start={startDate} end={endDate} count={5} />
      </div>
      <div className="flex flex-1 flex-row justify-center">
        <div className="flex max-w-sm flex-1 flex-row justify-around">
          <div
            className="flex w-10 cursor-pointer flex-col items-center justify-center px-2"
            onClick={previousSound}
          >
            <svg viewBox="0 0 90 60" fill="#ffffff">
              <polygon points="50,0 0,30 50,60" />
              <polygon points="90,0 40,30 90,60" />
            </svg>
          </div>
          <div
            className="flex w-16 cursor-pointer flex-col items-center justify-center rounded-full bg-blue-500 p-4 hover:bg-blue-700"
            onMouseUp={togglePlayPause}
          >
            <svg viewBox="0 0 60 60" fill="#ffffff">
              {isPlaying ? (
                <>
                  <polygon points="10,5 25,5 25,55 10,55" />
                  <polygon points="35,5 50,5 50,55 35,55" />
                </>
              ) : (
                <polygon points="10,0 60,30 10,60" />
              )}
            </svg>
          </div>
          <div
            className="flex w-10 cursor-pointer flex-col items-center justify-center px-2"
            onClick={nextSound}
          >
            <svg viewBox="0 0 90 60" fill="#ffffff">
              <polygon points="40,0 90,30 40,60" />
              <polygon points="0,0 50,30 0,60" />
            </svg>
          </div>
        </div>
      </div>
      <div>
        {data.map((soundData) => (
          <audio
            key={soundData.soundKey}
            src={soundURLs.current[soundData.soundKey]}
            ref={audioRefs.current[soundData.soundKey]}
          />
        ))}
      </div>
      {isLive && (
        <div
          className="absolute right-4 top-3 flex flex-row items-center gap-2"
          title="Mise à jour des sons en direct"
        >
          <span className="relative flex h-4 w-4">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex h-4 w-4 rounded-full bg-sky-500"></span>
          </span>
          <span className="text-sky-500">EN DIRECT</span>
        </div>
      )}
    </div>
  )
}

const TimeLineS3 = (props: Props['alert']) => {
  return (
    <S3Wrapper
      accessKeyId={props.accessKeyId}
      secretAccessKey={props.secretAccessKey}
      sessionToken={props.sessionToken}
    >
      {(s3Client) => <TimeLine_ s3Client={s3Client} alert={props} />}
    </S3Wrapper>
  )
}

export const TimeLine = React.memo(TimeLineS3)
