import { useState } from 'react'
import { useInterval } from 'shared/hooks/useInterval'
import { ms } from 'shared/utils/time'
import { TimeLine } from './TimeLine'
import { useFirebase } from './hooks/useFirebase'

const accessForbiddenMessage = "Cet accès a expiré ou n'est pas valide"

export function Content() {
  const uid = window.location.pathname

  // pathname always starts with a /
  if (uid.length < 2)
    return (
      <div className="flex min-h-screen flex-col justify-center text-center">
        {accessForbiddenMessage}
      </div>
    )

  return <UidContent uid={uid} />
}

export function UidContent({ uid }: { uid: string }) {
  const [now, setNow] = useState(Date.now())
  const { data: alert, loading, error } = useFirebase(`alerts/${uid}`)

  useInterval(
    () => {
      setNow(Date.now())
    },
    ms(60, 'seconds'),
  )

  if (loading)
    return (
      <div className="flex min-h-screen flex-col justify-center text-center">
        Chargement...
      </div>
    )

  if (error) throw error

  if (alert === undefined || alert.expiration < now)
    return (
      <div className="flex min-h-screen flex-col justify-center text-center">
        {accessForbiddenMessage}
      </div>
    )

  return (
    <div className="min-h-screen">
      <TimeLine {...alert} />
    </div>
  )
}

// Ajouter stats dernière alerte, nb/mois précédent...
